<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let settings;
  let slides = [];
  let links = [];
  let t_slides = [1, 2, 3];
  let t_s_slides = [4, 5, 6];
  let t_i_slides = [7, 8, 9, 10];
  async function render() {
    if (settings.length != 0) {
      settings.slides.forEach(function callback(value, idx) {
        if (idx != null && idx != "" && value != null && value != "") {
          slides[idx] = "";
          slides = slides.map((images, index) =>
            index === idx ? [...images, value[0]] : images
          );
        }
      });

      let tmp = settings.links;
      for (let i = 0; i < tmp.length; i++) {
        links = [...links, tmp[i]];
      }
      // settings.links.forEach(function callback(value, idx) {
      //   if (idx != null && idx != "" && value != null && value != "") {
      //     links[idx] = "";
      //     links = links.map((texts, index) =>
      //       index === idx ? [...texts, value] : texts
      //     );
      //   }
      // });
      dispatch("update", {
        lang: lang,
        slides: slides,
        links: links,
      });
    }
  }
  onMount(render);
  function onHide() {
    dispatch("hide");
  }
  async function onSelectImageSlide(e, idx) {
    const file = e.target.files[0];
    //slides = [...slides, await readAsDataURL(file)];
    let image = await readAsDataURL(file);
    slides[idx] = "";
    slides = slides.map((images, index) =>
      index === idx ? [...images, image] : images
    );
    e.target.value = null;
    dispatch("update", {
      lang: lang,
      slides: slides,
      links: links,
    });
  }
  function update() {
    dispatch("update", {
      lang: lang,
      slides: slides,
      links: links,
    });
  }
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
</script>

<div class="slides-lang-settings-wrap container">
  <div class="back" on:click={gotoBack}>&lt; Slides</div>
  <div class="w-full flex" style="justify-content: space-between;">
    {#each t_slides as t_s}
      <div>
        <label
          class="logo flex justify-center items-center cursor-pointer"
          for="{lang}slide{t_s}"
          style={typeof slides[t_s] !== "undefined"
            ? "background:url(" + slides[t_s] + ")"
            : ""}
        >
          <img src="/image.svg" />
        </label>
        <input
          type="text"
          placeholder="Link"
          bind:value={links[t_s]}
          on:blur={update}
        />
        <input
          type="file"
          id="{lang}slide{t_s}"
          name="{lang}slide{t_s}"
          class="hidden"
          on:change={(event) => onSelectImageSlide(event, t_s)}
        />
      </div>
    {/each}
    <div />
  </div>
  <div class="text title">Sign in Slides</div>
  <div class="w-full flex" style="justify-content: space-between;">
    {#each t_s_slides as t_s}
      <div>
        <label
          class="logo flex justify-center items-center cursor-pointer"
          for="{lang}slide{t_s}"
          style={typeof slides[t_s] !== "undefined"
            ? "background:url(" + slides[t_s] + ")"
            : ""}
        >
          <img src="/image.svg" />
        </label>
        <input
          type="text"
          placeholder="Link"
          bind:value={links[t_s]}
          on:blur={update}
        />
        <input
          type="file"
          id="{lang}slide{t_s}"
          name="{lang}slide{t_s}"
          class="hidden"
          on:change={(event) => onSelectImageSlide(event, t_s)}
        />
      </div>
    {/each}
    <div />
  </div>
  <div class="text title">Intro Slides</div>
  <div class="w-full flex" style="justify-content: space-between;">
    {#each t_i_slides as t_s}
      <div>
        <label
          class="logo flex justify-center items-center cursor-pointer"
          for="{lang}slide{t_s}"
          style={typeof slides[t_s] !== "undefined"
            ? "background:url(" + slides[t_s] + ")"
            : ""}
        >
          <img src="/image.svg" />
        </label>
        {#if t_s == 8}
          <input
            type="text"
            placeholder="Link"
            bind:value={links[t_s]}
            on:blur={update}
          />
        {/if}
        <input
          type="file"
          id="{lang}slide{t_s}"
          name="{lang}slide{t_s}"
          class="hidden"
          on:change={(event) => onSelectImageSlide(event, t_s)}
        />
      </div>
    {/each}
  </div>
</div>

<style>.back:hover {
  cursor: pointer;
}

.slides-lang-settings-wrap input[type="text"],
.slides-lang-settings-wrap textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.slides-lang-settings-wrap > div > div {
  min-width: 100px;
  width: 24%;
}

.slides-lang-settings-wrap label.flex {
  min-height: 100px;
  /* background: #7a7e81; */
  margin-top: 10px;
  border: 1px solid #7a7e81;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  border-radius: 10px;
}

.slides-lang-settings-wrap div.text.title {
  font-size: 20px;
  margin-top: 15px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9TbGlkZXMuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsZUFBZTtBQUNqQjs7QUFDQTs7RUFFRSxpQkFBaUI7RUFDakIsc0JBQXNCO0VBQ3RCLG1CQUFtQjtFQUNuQixnQkFBZ0I7RUFDaEIsV0FBVztBQUNiOztBQUNBO0VBQ0UsZ0JBQWdCO0VBQ2hCLFVBQVU7QUFDWjs7QUFDQTtFQUNFLGlCQUFpQjtFQUNqQix5QkFBeUI7RUFDekIsZ0JBQWdCO0VBQ2hCLHlCQUF5QjtFQUN6QixpQ0FBaUM7RUFDakMsdUNBQXVDO0VBQ3ZDLDZDQUE2QztFQUM3QyxtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxlQUFlO0VBQ2YsZ0JBQWdCO0FBQ2xCIiwiZmlsZSI6InNyYy9TbGlkZXMuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4uYmFjazpob3ZlciB7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cbi5zbGlkZXMtbGFuZy1zZXR0aW5ncy13cmFwIGlucHV0W3R5cGU9XCJ0ZXh0XCJdLFxuLnNsaWRlcy1sYW5nLXNldHRpbmdzLXdyYXAgdGV4dGFyZWEge1xuICBwYWRkaW5nOiA1cHggMTBweDtcbiAgYm9yZGVyOiAxcHggc29saWQgI2NjYztcbiAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgbWFyZ2luLXRvcDogMTBweDtcbiAgd2lkdGg6IDEwMCU7XG59XG4uc2xpZGVzLWxhbmctc2V0dGluZ3Mtd3JhcCA+IGRpdiA+IGRpdiB7XG4gIG1pbi13aWR0aDogMTAwcHg7XG4gIHdpZHRoOiAyNCU7XG59XG4uc2xpZGVzLWxhbmctc2V0dGluZ3Mtd3JhcCBsYWJlbC5mbGV4IHtcbiAgbWluLWhlaWdodDogMTAwcHg7XG4gIC8qIGJhY2tncm91bmQ6ICM3YTdlODE7ICovXG4gIG1hcmdpbi10b3A6IDEwcHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkICM3YTdlODE7XG4gIGJhY2tncm91bmQtc2l6ZTogY292ZXIgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1yZXBlYXQ6IG5vLXJlcGVhdCAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiBjZW50ZXIgY2VudGVyICFpbXBvcnRhbnQ7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG59XG4uc2xpZGVzLWxhbmctc2V0dGluZ3Mtd3JhcCBkaXYudGV4dC50aXRsZSB7XG4gIGZvbnQtc2l6ZTogMjBweDtcbiAgbWFyZ2luLXRvcDogMTVweDtcbn1cbiJdfQ== */</style>
