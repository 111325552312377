<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let questions;
  export let user_id;
  let newId = 0;
  async function render() {
    console.log("user_id", user_id);
  }
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  function update() {
    dispatch("update", {
      type: "question",
      lang: lang,
      questions: questions,
    });
  }
  async function remove(id) {
    let body = new FormData();
    body.append("act", "deleteQuestion");
    body.append("id", id);
    const res = await fetch("https://qix.cloud/ajax/app_settings.php", {
      method: "POST",
      body: body,
    });
    let tmp = questions;
    questions = [];
    for (let i = 0; i < tmp.length; i++) {
      if (id != tmp[i].id) {
        questions = [...questions, tmp[i]];
      }
    }
    update();
  }
  function addNew() {
    newId++;
    questions = [
      ...questions,
      {
        id: lang + "_" + newId,
        user_id: user_id,
        lang: lang,
        title: "",
        description: "",
        created: "",
      },
    ];
    console.log("questions", questions);
    update();
  }

  onMount(render);
</script>

<div class="questions-lang-settings-wrap container">
  <div class="flex items-center">
    <div class="back" on:click={gotoBack}>&lt; Questions</div>
    <button
      class="addBtn w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 md:px-4 rounded"
      on:click={addNew}>+ Add</button
    >
  </div>
  <table>
    <tr><th>Title</th><th>Desciption</th><th /></tr>
    {#each questions as data}
      {#if data.lang == lang}
        <tr>
          <td>
            <input type="text" bind:value={data.title} on:blur={update} />
          </td>
          <td>
            <textarea bind:value={data.description} on:blur={update} />
          </td>
          <td style="vertical-align:middle;"
            ><img
              src="/delete.png"
              class="cursor-pointer"
              style="width: 20px;"
              on:click={() => remove(data.id)}
            /></td
          >
        </tr>
      {/if}
    {/each}
  </table>
</div>

<style>.back {
  margin-right: 30px;
}

.back:hover {
  cursor: pointer;
}

.questions-lang-settings-wrap input[type="text"],
.questions-lang-settings-wrap textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.questions-lang-settings-wrap table {
  width: 100%;
  margin-top: 20px;
}

.questions-lang-settings-wrap textarea {
  height: 36px;
}

.questions-lang-settings-wrap table td {
  vertical-align: top;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9RdWVzdGlvbnMuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0Usa0JBQWtCO0FBQ3BCOztBQUNBO0VBQ0UsZUFBZTtBQUNqQjs7QUFDQTs7RUFFRSxpQkFBaUI7RUFDakIsc0JBQXNCO0VBQ3RCLG1CQUFtQjtFQUNuQixnQkFBZ0I7RUFDaEIsV0FBVztBQUNiOztBQUNBO0VBQ0UsV0FBVztFQUNYLGdCQUFnQjtBQUNsQjs7QUFDQTtFQUNFLFlBQVk7QUFDZDs7QUFDQTtFQUNFLG1CQUFtQjtBQUNyQiIsImZpbGUiOiJzcmMvUXVlc3Rpb25zLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmJhY2sge1xuICBtYXJnaW4tcmlnaHQ6IDMwcHg7XG59XG4uYmFjazpob3ZlciB7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cbi5xdWVzdGlvbnMtbGFuZy1zZXR0aW5ncy13cmFwIGlucHV0W3R5cGU9XCJ0ZXh0XCJdLFxuLnF1ZXN0aW9ucy1sYW5nLXNldHRpbmdzLXdyYXAgdGV4dGFyZWEge1xuICBwYWRkaW5nOiA1cHggMTBweDtcbiAgYm9yZGVyOiAxcHggc29saWQgI2NjYztcbiAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgbWFyZ2luLXRvcDogMTBweDtcbiAgd2lkdGg6IDEwMCU7XG59XG4ucXVlc3Rpb25zLWxhbmctc2V0dGluZ3Mtd3JhcCB0YWJsZSB7XG4gIHdpZHRoOiAxMDAlO1xuICBtYXJnaW4tdG9wOiAyMHB4O1xufVxuLnF1ZXN0aW9ucy1sYW5nLXNldHRpbmdzLXdyYXAgdGV4dGFyZWEge1xuICBoZWlnaHQ6IDM2cHg7XG59XG4ucXVlc3Rpb25zLWxhbmctc2V0dGluZ3Mtd3JhcCB0YWJsZSB0ZCB7XG4gIHZlcnRpY2FsLWFsaWduOiB0b3A7XG59XG4iXX0= */</style>
