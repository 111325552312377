<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let glossaries;
  export let user_id;
  let newId = 0;
  async function render() {
    console.log("user_id", user_id);
  }
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  function update() {
    dispatch("update", {
      type: "glossary",
      lang: lang,
      glossaries: glossaries,
    });
  }
  async function remove(id) {
    let body = new FormData();
    body.append("act", "deleteGlossary");
    body.append("id", id);
    const res = await fetch("https://qix.cloud/ajax/app_settings.php", {
      method: "POST",
      body: body,
    });
    let tmp = glossaries;
    glossaries = [];
    for (let i = 0; i < tmp.length; i++) {
      if (id != tmp[i].id) {
        glossaries = [...glossaries, tmp[i]];
      }
    }
    update();
  }
  function addNew() {
    newId++;
    glossaries = [
      ...glossaries,
      {
        id: lang + "_" + newId,
        user_id: user_id,
        lang: lang,
        name: "",
        description: "",
        created: "",
      },
    ];
    console.log("glossaries", glossaries);
    update();
  }
  onMount(render);
</script>

<div class="glossary-lang-settings-wrap container">
  <div class="flex items-center">
    <div class="back" on:click={gotoBack}>&lt; Glossary</div>
    <button
      class="addBtn w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 md:px-4 rounded"
      on:click={addNew}>+ Add</button
    >
  </div>
  <table>
    <tr><th>Name</th><th>Desciption</th><th /></tr>
    {#each glossaries as data}
      {#if data.lang == lang}
        <tr>
          <td>
            <input type="text" bind:value={data.name} on:blur={() => update} />
          </td>
          <td>
            <textarea bind:value={data.description} on:blur={() => update} />
          </td>
          <td style="vertical-align:middle;"
            ><img
              src="/delete.png"
              class="cursor-pointer"
              style="width: 20px;"
              on:click={() => remove(data.id)}
            /></td
          >
        </tr>
      {/if}
    {/each}
  </table>
</div>

<style>.back {
  margin-right: 30px;
}

.back:hover {
  cursor: pointer;
}

.glossary-lang-settings-wrap input[type="text"],
.glossary-lang-settings-wrap textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.glossary-lang-settings-wrap table {
  width: 100%;
  margin-top: 20px;
}

.glossary-lang-settings-wrap textarea {
  height: 36px;
}

.glossary-lang-settings-wrap table td {
  vertical-align: top;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9HbG9zc2FyeS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRSxrQkFBa0I7QUFDcEI7O0FBQ0E7RUFDRSxlQUFlO0FBQ2pCOztBQUNBOztFQUVFLGlCQUFpQjtFQUNqQixzQkFBc0I7RUFDdEIsbUJBQW1CO0VBQ25CLGdCQUFnQjtFQUNoQixXQUFXO0FBQ2I7O0FBQ0E7RUFDRSxXQUFXO0VBQ1gsZ0JBQWdCO0FBQ2xCOztBQUNBO0VBQ0UsWUFBWTtBQUNkOztBQUNBO0VBQ0UsbUJBQW1CO0FBQ3JCIiwiZmlsZSI6InNyYy9HbG9zc2FyeS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5iYWNrIHtcbiAgbWFyZ2luLXJpZ2h0OiAzMHB4O1xufVxuLmJhY2s6aG92ZXIge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG4uZ2xvc3NhcnktbGFuZy1zZXR0aW5ncy13cmFwIGlucHV0W3R5cGU9XCJ0ZXh0XCJdLFxuLmdsb3NzYXJ5LWxhbmctc2V0dGluZ3Mtd3JhcCB0ZXh0YXJlYSB7XG4gIHBhZGRpbmc6IDVweCAxMHB4O1xuICBib3JkZXI6IDFweCBzb2xpZCAjY2NjO1xuICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICBtYXJnaW4tdG9wOiAxMHB4O1xuICB3aWR0aDogMTAwJTtcbn1cbi5nbG9zc2FyeS1sYW5nLXNldHRpbmdzLXdyYXAgdGFibGUge1xuICB3aWR0aDogMTAwJTtcbiAgbWFyZ2luLXRvcDogMjBweDtcbn1cbi5nbG9zc2FyeS1sYW5nLXNldHRpbmdzLXdyYXAgdGV4dGFyZWEge1xuICBoZWlnaHQ6IDM2cHg7XG59XG4uZ2xvc3NhcnktbGFuZy1zZXR0aW5ncy13cmFwIHRhYmxlIHRkIHtcbiAgdmVydGljYWwtYWxpZ246IHRvcDtcbn1cbiJdfQ== */</style>
