<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let settings;
  let tab = "";
  async function render() {}
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  onMount(render);
</script>

<div class="misc-lang-settings-wrap container">
  <div class="back" on:click={gotoBack}>&lt; Misc</div>
</div>

<style>.back:hover {
  cursor: pointer;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9NaXNjLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGVBQWU7QUFDakIiLCJmaWxlIjoic3JjL01pc2Muc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4uYmFjazpob3ZlciB7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cbiJdfQ== */</style>
