<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  let t_status = [1, 2, 3, 4, 5];
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let statuses;
  async function render() {}
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  onMount(render);
  function update() {
    dispatch("update", {
      type: "status",
      lang: lang,
      statuses: statuses,
    });
  }
</script>

<div class="status-lang-settings-wrap container">
  <div class="back" on:click={gotoBack}>&lt; Status</div>
  {#each statuses as data, idx}
    <div style="margin-top: 20px">Status {idx + 1}</div>
    <div class="flex">
      <div style="margin-right: 20px;">
        <input
          type="text"
          placeholder="Name"
          bind:value={data.name}
          on:blur={update}
        />
        <input
          type="text"
          placeholder="Tag ID"
          bind:value={data.tagId}
          on:blur={update}
        />
      </div>
      <textarea
        placeholder="Desription"
        bind:value={data.description}
        on:blur={update}
      />
    </div>
  {/each}
</div>

<style>.back:hover {
  cursor: pointer;
}

.status-lang-settings-wrap input[type="text"],
.status-lang-settings-wrap textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.status-lang-settings-wrap textarea {
  resize: none;
  height: 82px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9TdGF0dXMuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsZUFBZTtBQUNqQjs7QUFDQTs7RUFFRSxpQkFBaUI7RUFDakIsc0JBQXNCO0VBQ3RCLG1CQUFtQjtFQUNuQixnQkFBZ0I7RUFDaEIsV0FBVztBQUNiOztBQUNBO0VBQ0UsWUFBWTtFQUNaLFlBQVk7QUFDZCIsImZpbGUiOiJzcmMvU3RhdHVzLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmJhY2s6aG92ZXIge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG4uc3RhdHVzLWxhbmctc2V0dGluZ3Mtd3JhcCBpbnB1dFt0eXBlPVwidGV4dFwiXSxcbi5zdGF0dXMtbGFuZy1zZXR0aW5ncy13cmFwIHRleHRhcmVhIHtcbiAgcGFkZGluZzogNXB4IDEwcHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkICNjY2M7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gIG1hcmdpbi10b3A6IDEwcHg7XG4gIHdpZHRoOiAxMDAlO1xufVxuLnN0YXR1cy1sYW5nLXNldHRpbmdzLXdyYXAgdGV4dGFyZWEge1xuICByZXNpemU6IG5vbmU7XG4gIGhlaWdodDogODJweDtcbn1cbiJdfQ== */</style>
