<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let articles;
  async function render() {
    console.log("articles", articles);
  }
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  onMount(render);

  async function onSelectImageSlide(e, idx) {
    const file = e.target.files[0];
    let image = await readAsDataURL(file);
    let tmp = articles;
    tmp[idx].img = image;
    articles = [];
    for (let i = 0; i < tmp.length; i++) {
      articles = [...articles, tmp[i]];
    }
    e.target.value = null;
    update();
  }
  function update() {
    dispatch("update", {
      type: "article",
      lang: lang,
      articles: articles,
    });
  }
  function adjustText(idx) {
    let tmp = articles;
    if (tmp[idx].description.length > 875) {
      tmp[idx].description = tmp[idx].description.substring(0, 875);
    }
    articles = [];
    for (let i = 0; i < tmp.length; i++) {
      articles = [...articles, tmp[i]];
    }
    update();
  }
</script>

<div class="article-lang-settings-wrap container">
  <div class="back" on:click={gotoBack}>&lt; Article</div>
  <div class="w-full flex" style="justify-content: center;flex-wrap: wrap;">
    {#each articles as data, idx}
      <div>
        <label
          class="logo flex justify-center items-center cursor-pointer"
          for="{lang}article{idx}"
          style={data.img !== "" ? "background:url(" + data.img + ")" : ""}
        >
          <img src="/image.svg" />
        </label>
        <input
          type="text"
          placeholder="Title"
          bind:value={data.title}
          on:blur={update}
        />
        <input
          type="text"
          placeholder="Caption"
          bind:value={data.caption}
          on:blur={update}
        />
        <textarea
          placeholder="Desription"
          bind:value={data.description}
          on:input={() => adjustText(idx)}
          on:blur={update}
        />
        <span class="badge">{data.description.length}/875</span>
        <input
          type="file"
          id="{lang}article{idx}"
          name="{lang}article{idx}"
          class="hidden"
          on:change={(event) => onSelectImageSlide(event, idx)}
        />
      </div>
    {/each}
  </div>
</div>

<style>.back:hover {
  cursor: pointer;
}

.article-lang-settings-wrap input[type="text"],
.article-lang-settings-wrap textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.article-lang-settings-wrap textarea {
  resize: none;
  height: 100px;
}

.article-lang-settings-wrap > div > div {
  position: relative;
  min-width: 100px;
  width: 30%;
  padding: 1%;
}

.article-lang-settings-wrap label.flex {
  min-height: 100px;
  /* background: #7a7e81; */
  margin-top: 10px;
  border: 1px solid #7a7e81;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  border-radius: 10px;
}

.badge {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 12px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9BcnRpY2xlLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGVBQWU7QUFDakI7O0FBQ0E7O0VBRUUsaUJBQWlCO0VBQ2pCLHNCQUFzQjtFQUN0QixtQkFBbUI7RUFDbkIsZ0JBQWdCO0VBQ2hCLFdBQVc7QUFDYjs7QUFDQTtFQUNFLFlBQVk7RUFDWixhQUFhO0FBQ2Y7O0FBQ0E7RUFDRSxrQkFBa0I7RUFDbEIsZ0JBQWdCO0VBQ2hCLFVBQVU7RUFDVixXQUFXO0FBQ2I7O0FBQ0E7RUFDRSxpQkFBaUI7RUFDakIseUJBQXlCO0VBQ3pCLGdCQUFnQjtFQUNoQix5QkFBeUI7RUFDekIsaUNBQWlDO0VBQ2pDLHVDQUF1QztFQUN2Qyw2Q0FBNkM7RUFDN0MsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0Usa0JBQWtCO0VBQ2xCLFdBQVc7RUFDWCxZQUFZO0VBQ1osZUFBZTtBQUNqQiIsImZpbGUiOiJzcmMvQXJ0aWNsZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5iYWNrOmhvdmVyIHtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuLmFydGljbGUtbGFuZy1zZXR0aW5ncy13cmFwIGlucHV0W3R5cGU9XCJ0ZXh0XCJdLFxuLmFydGljbGUtbGFuZy1zZXR0aW5ncy13cmFwIHRleHRhcmVhIHtcbiAgcGFkZGluZzogNXB4IDEwcHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkICNjY2M7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gIG1hcmdpbi10b3A6IDEwcHg7XG4gIHdpZHRoOiAxMDAlO1xufVxuLmFydGljbGUtbGFuZy1zZXR0aW5ncy13cmFwIHRleHRhcmVhIHtcbiAgcmVzaXplOiBub25lO1xuICBoZWlnaHQ6IDEwMHB4O1xufVxuLmFydGljbGUtbGFuZy1zZXR0aW5ncy13cmFwID4gZGl2ID4gZGl2IHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBtaW4td2lkdGg6IDEwMHB4O1xuICB3aWR0aDogMzAlO1xuICBwYWRkaW5nOiAxJTtcbn1cbi5hcnRpY2xlLWxhbmctc2V0dGluZ3Mtd3JhcCBsYWJlbC5mbGV4IHtcbiAgbWluLWhlaWdodDogMTAwcHg7XG4gIC8qIGJhY2tncm91bmQ6ICM3YTdlODE7ICovXG4gIG1hcmdpbi10b3A6IDEwcHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkICM3YTdlODE7XG4gIGJhY2tncm91bmQtc2l6ZTogY292ZXIgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1yZXBlYXQ6IG5vLXJlcGVhdCAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiBjZW50ZXIgY2VudGVyICFpbXBvcnRhbnQ7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG59XG4uYmFkZ2Uge1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHJpZ2h0OiAyMHB4O1xuICBib3R0b206IDIwcHg7XG4gIGZvbnQtc2l6ZTogMTJweDtcbn1cbiJdfQ== */</style>
