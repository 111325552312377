<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import Slides from "./Slides.svelte";
  import Glossary from "./Glossary.svelte";
  import Questions from "./Questions.svelte";
  import Article from "./Article.svelte";
  import Status from "./Status.svelte";
  import Misc from "./Misc.svelte";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let settings;
  export let glossaries;
  export let questions;
  export let articles;
  export let statuses;
  export let user_id;
  let tab = "slides";
  async function render() {}
  function updateSlides(payload) {
    dispatch("update", payload);
  }
  function updateExtraData(payload) {
    dispatch("updateExtraData", payload);
  }
  onMount(render);
</script>

<div class="lang-settings-wrap container">
  {#if tab == ""}
    <div
      class="w-full flex"
      style="justify-content: space-between;flex-wrap: wrap;"
    >
      <div class="tab" on:click={() => (tab = "slides")}>
        <img src="/slides.png" />
        <div>Slides</div>
      </div>
      <div class="tab" on:click={() => (tab = "glossary")}>
        <img src="/glossary.png" />
        <div>Glossary</div>
      </div>
      <div class="tab" on:click={() => (tab = "questions")}>
        <img src="/questions.png" />
        <div>Questions</div>
      </div>
      <div class="tab" on:click={() => (tab = "article")}>
        <img src="/articles.png" />
        <div>Articles</div>
      </div>
      <div class="tab" on:click={() => (tab = "status")}>
        <img src="/status.png" />
        <div>Status</div>
      </div>
      <div class="tab" on:click={() => (tab = "misc")}>
        <img src="/misc.png" />
        <div>Misk</div>
      </div>
    </div>
  {:else if tab == "slides"}
    <Slides
      {lang}
      {settings}
      on:update={(e) => updateSlides(e.detail)}
      on:back={(e) => (tab = "")}
    />
  {:else if tab == "glossary"}
    <Glossary
      {lang}
      {glossaries}
      {user_id}
      on:update={(e) => updateExtraData(e.detail)}
      on:back={(e) => (tab = "")}
    />
  {:else if tab == "questions"}
    <Questions
      {lang}
      {questions}
      {user_id}
      on:update={(e) => updateExtraData(e.detail)}
      on:back={(e) => (tab = "")}
    />
  {:else if tab == "article"}
    <Article
      {lang}
      {articles}
      {user_id}
      on:update={(e) => updateExtraData(e.detail)}
      on:back={(e) => (tab = "")}
    />
  {:else if tab == "status"}
    <Status
      {lang}
      {statuses}
      {user_id}
      on:update={(e) => updateExtraData(e.detail)}
      on:back={(e) => (tab = "")}
    />
  {:else if tab == "misc"}
    <Misc
      {lang}
      {settings}
      {user_id}
      on:update={(e) => updateExtraData(e.detail)}
      on:back={(e) => (tab = "")}
    />
  {/if}
</div>

<style>.lang-settings-wrap .tab {
  width: 200px;
  height: 200px;
  background: #cfe6f4;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  flex-direction: column;
}

.lang-settings-wrap .tab:hover {
  background: #6ec4f3;
  cursor: pointer;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9TZXR0aW5nc0xhbmcuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsWUFBWTtFQUNaLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsYUFBYTtFQUNiLG1CQUFtQjtFQUNuQix1QkFBdUI7RUFDdkIsbUJBQW1CO0VBQ25CLGVBQWU7RUFDZixtQkFBbUI7RUFDbkIsc0JBQXNCO0FBQ3hCOztBQUNBO0VBQ0UsbUJBQW1CO0VBQ25CLGVBQWU7QUFDakIiLCJmaWxlIjoic3JjL1NldHRpbmdzTGFuZy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5sYW5nLXNldHRpbmdzLXdyYXAgLnRhYiB7XG4gIHdpZHRoOiAyMDBweDtcbiAgaGVpZ2h0OiAyMDBweDtcbiAgYmFja2dyb3VuZDogI2NmZTZmNDtcbiAgZGlzcGxheTogZmxleDtcbiAgYm9yZGVyLXJhZGl1czogMTVweDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGZsZXgtd3JhcDogd3JhcDtcbiAgbWFyZ2luLWJvdHRvbTogMjBweDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbn1cbi5sYW5nLXNldHRpbmdzLXdyYXAgLnRhYjpob3ZlciB7XG4gIGJhY2tncm91bmQ6ICM2ZWM0ZjM7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cbiJdfQ== */</style>
